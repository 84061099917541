import React from 'react';

export const Arrow = (props) => {
  let deg = '0deg';
  switch (props.direction) {
    case 'up':
      deg = '-90deg';
      break;
    case 'down':
      deg = '90deg';
      break;
    case 'left':
      deg = '180deg';
      break;
    default:
      break;
  }
  return (
    <svg viewBox="0 0 20 18" {...props} className="arrow" style={{ transform: `rotate(${deg})` }}>
      <path
        fill="#000"
        id="prefix__a"
        d="M12.864 0l-1.896 1.895 5.603 5.603L0 7.332l.027 2.708 16.799.168-5.717 5.717 1.934 1.933 6.956-6.957H20l-.038-3.795h-.008l.008-.008z"
      />
    </svg>
  )
}

