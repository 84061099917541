import React, { Component, Fragment } from 'react';
import GatsbyLink from '../GatsbyLink';
import { FaChevronDown } from 'react-icons/fa';
import './HeaderNav.scss'
import { decodeEntities } from '../../utils/helpers';
import { Arrow } from '../Svg';

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, toggleNav } = props;
  const {
    slug,
    object_id,
    title,
    url,
    children,
    target,
    description,
    megaMenuTitle,
    megaMenuLink
  } = item;
  const isOpen = openNavKey === object_id;
  const hasMegaMenuContent = megaMenuTitle || description || megaMenuLink;
  return (
    <span
      key={object_id}
      className={hasMegaMenuContent ? 'has-content' : ''}
    >
      <GatsbyLink
        to={url}
        onClick={() => {
          enableBodyScroll();
          toggleNav();
        }}
        target={target ? target : null}
        activeClassName="active"
        aria-label={title}
        decode
      >
        {title}
      </GatsbyLink>
      {children && children[0].url !== '#gatsby' && (
        <Fragment>
          <div className={`mega-menu ${isOpen ? 'open' : 'close'}`}>
            {hasMegaMenuContent && (
              <div className="mega-menu-content">
                {megaMenuTitle && <span className="mega-menu-content-title">{decodeEntities(megaMenuTitle)}</span>}
                {description && <div className="mega-menu-content-description"><p>{decodeEntities(description)}</p></div>}
                {megaMenuLink && <GatsbyLink className="mega-menu-content-link" to={megaMenuLink.url}><span>{decodeEntities(megaMenuLink.title)}</span></GatsbyLink>}
              </div>
            )}
            <NavChildren baseUrl={baseUrl} childNav={children} toggleNav={toggleNav} />
          </div>
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            {/* <FaChevronDown /> */}
          </button>
        </Fragment>
      )}
    </span>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl, toggleNav } = props;
  return (
    <ul className="children">
      {childNav.map((child, index) => {
        const {
          classes,
          object_id,
          slug,
          title,
          url,
          target,
          children
        } = child;
        return (
          <li key={index}>
            <GatsbyLink
              to={url}
              onClick={() => {
                enableBodyScroll();
                toggleNav();
              }}
              target={target ? target : null}
              activeClassName="active"
              aria-label={title}
              decode
            >
              {title}
            </GatsbyLink>
            {children && (
              <ul>
                {children.map((child, index) => {
                  const {
                    title: innerTitle,
                    url: innerUrl,
                    target: innerTarget,
                  } = child;
                  return (
                    <li key={index}>
                      <GatsbyLink
                        to={innerUrl}
                        onClick={() => {
                          enableBodyScroll();
                          toggleNav();
                        }}
                        target={innerTarget ? innerTarget : null}
                        activeClassName="active"
                        aria-label={innerTitle}
                        decode
                      >
                        {innerTitle}
                      </GatsbyLink>
                    </li>
                  )
                })}
              </ul>
            )}
          </li>
        )
      })}
    </ul>
  );
}

const SERVICES_TYPE = 'services-nav';
const COMMUNCIATIONS_TYPE = 'communication-nav';
const COMPANY_TYPE = 'company-nav';

export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl, className, toggleNav } = this.props;
    const { openNavKey } = this.state;
    return (

        <ul className={className}>
          {navItems && navItems.map((col, index) => index < 10 && (
            <li
              key={index}
            >
              <NavItem key={col.url} item={col} baseUrl={baseUrl} toggleSubNav={this.toggleSubNav} toggleNav={toggleNav} openNavKey={openNavKey} />
            </li>
          ))}
        </ul>

    );
  }
}
