import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
//import { FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import { SocialLinks } from '../SocialLinks';
import { decodeEntities } from '../../utils/helpers';
import './Footer.scss';

const isClient = typeof window !== 'undefined';

class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }

  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { items: legalNav } = legalNavigation;
    const { footerTagline, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();
    const isHome = location.pathname === '/';
    const BirdBrainLink = () => (
      isHome ? (
        <a
          href="https://distl.com.au/"
          target="_blank"
          className="bb"
          rel="noopener noreferrer"
        >
          Distl
        </a>
      ) : <span className="bb">Distl</span>
    );
    return (
      <>
        <footer>
          <div className="footer-main">
            <div className="inner">
              <div className="wrap">
                <div className="logos">
                  <Logo />
                  {footerTagline && <RenderContent className="tagline" content={footerTagline}/>}
                </div>
                {contactDetailsInFooter && (
                  <div className="contact-details">
                    {addressDisplay && (
                      <div className="address">
                        {addressDisplay}
                      </div>
                    )}
                    {phone && (
                      <div className="phone">
                        <a
                          href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                          className='control-call'
                          aria-label="Call Now"
                        >
                          {phone}
                        </a>
                      </div>
                    )}
                    {email && (
                      <div className="email">
                        <a href="#" onClick={(event) => this.sendEmail(event)}><span dangerouslySetInnerHTML={{__html: email}} /></a>
                      </div>
                    )}
                    {siteSettings.options.socialLinksFooter && (
                      <SocialLinks />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="disclaimers">
            <div className="wrap">
              <div className="disclaimers-left">
                {legalNav && (
                  <div className="navigation">
                    <ul>
                      {legalNav.map((item, index) => (
                        <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="copyright">
                  <span>Copyright {currentYear} {wordpressWpSettings.title}</span>
                </div>
              </div>
              <div className="disclaimers-right">
                <span className="credit">
                  Website by <BirdBrainLink />
                </span>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
            socialLinksFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
